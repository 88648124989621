import * as React from "react"
import AboutHero from "../components/AboutHero/AboutHero"
import MissionStatement from "../components/MissionStatement/MissionStatement"
import Seo from "../components/Seo/Seo"
import { graphql } from "gatsby"

const AboutErgsensePage = ({ data: { wpPage } }) => {
  return (
    <>
      <Seo title="About Ergsense" />
      <MissionStatement
        headingText={wpPage.missionStatement.headingText2}
        subHeadingText={wpPage.missionStatement.subheadingText2}
        paragraphText={wpPage.missionStatement.paragraphText2}
        quoteText={wpPage.missionStatement.quoteText}
        quoteAuthor={wpPage.missionStatement.quoteAuthor}
        quoteAuthor2={wpPage.missionStatement.quoteAuthor2}
        image={wpPage.missionStatement.quoteImage.localFile}
        altText={wpPage.missionStatement.quoteImage.altText}
      />
      <AboutHero
        headingText={wpPage.aboutHero.headingText}
        subHeadingText={wpPage.aboutHero.subheadingText}
        paragraphText={wpPage.aboutHero.paragraphText}
        image={wpPage.aboutHero.heroImage.localFile}
        altText={wpPage.aboutHero.heroImage.altText}
      />
    </>
  )
}

export default AboutErgsensePage

export const query = graphql`
  query {
    wpPage(databaseId: { eq: 183 }) {
      uri
      title
      missionStatement {
        headingText2
        subheadingText2
        paragraphText2
        quoteText
        quoteAuthor
        quoteAuthor2
        quoteImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 800, quality: 100)
            }
          }
        }
      }
      aboutHero {
        headingText
        subheadingText
        paragraphText
        heroImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1800, quality: 100)
            }
          }
        }
      }
    }
  }
`
