import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as containerStyles from "./AboutHero.module.css"

const AboutHero = props => {
  return (
    <div className="relative bg-white overflow-hidden">
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <GatsbyImage
          className="h-60 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          image={getImage(props.image)}
          alt={props.altText}
          objectPosition="center top"
        />
      </div>
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-4xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <div className="mt-0 pt-12 mx-auto max-w-7xl md: md:pt-0 px-4 sm:px-6">
            <div className="sm:text-center lg:text-left">
              <h2 className="text-3xl pt-12 font-extrabold tracking-tight text-secondary md:text-4xl text-center">
                {props.headingText}
              </h2>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg md:mt-5 md:text-lg">
                {props.subHeadingText}
              </p>
              <div
                className={`mt-3 text-base text-gray-500 leading-7 relative z-20 ${containerStyles.heroText}`}
                dangerouslySetInnerHTML={{
                  __html: props.paragraphText,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutHero
